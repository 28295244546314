<template>
  <div
    class="flex flex-col items-start flex-grow p-4 mb-4 bg-white card"
  >
    <TbIcon
      :icon="props.icon"
      class="mb-8 text-xl lg:text-2xl text-primary"
    />
    <div class="">
      <h2 class="mb-2 text-base font-bold">
        {{ props.name }}
      </h2>

      <p class="mb-4 text-sm">
        {{ props.description }}
      </p>
    </div>

    <a
      :href="props.link"
      target="_blank"
      class="font-bold"
    >
      {{ props.linkText }}
    </a>
  </div>
</template>

<script setup lang="ts">
import { TbIcon } from '@/components/tasty_bistro';

interface Props {
  name: string;
  description: string;
  icon: string;
  linkText: string;
  link: string;
}

const props = defineProps<Props>();

</script>
