<template>
  <TbLetterLogo class="h-20 w-20 m-auto mb-3" />
  <div class="mb-3 text-center">
    <h1 class="py-2 text-xl font-medium lg:text-2xl">
      {{ props.heading }}
    </h1>
    <p class="text-sm lg:text-base">
      {{ props.subHeading }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { TbLetterLogo } from '@/components/tasty_bistro';

interface FormWrapperProps {
  heading: string;
  subHeading: string;
}

const props = defineProps<FormWrapperProps>();
</script>
