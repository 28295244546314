<template>
  <router-view
    v-if="state"
    v-slot="{ Component }"
  >
    <div class="h-full min-h-full">
      <component :is="Component" />
    </div>
  </router-view>
</template>

<script lang="ts" setup>
import {
  onBeforeMount, provide, Ref, ref,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Pages } from '@/router';
import { tracker } from '@openreplay/tracker';
import { roleConfig } from '@/pages/app/project/dashboard/components/role_configurations';
import { getAppState, AppState } from './api/get_app_state';

const route = useRoute();
const router = useRouter();

const state: Ref<AppState | undefined> = ref();
provide('state', state);

function getRedirectPage(roleName: string) {
  const hasBlockedHome = roleConfig.dashboardHome[roleName].restricted || roleConfig.dashboardHome[roleName].hidden;
  if (hasBlockedHome) {
    return Pages.checkouts;
  }
  return Pages.dashboardHome;
}

onBeforeMount(async () => {
  try {
    const appState = await getAppState();
    state.value = appState;

    tracker.setUserID(appState.user.id);

    if (!route.params.projectId) {
      const projectId = Object.keys(appState.projects)[0];
      const roleName = appState.projects[projectId].currentUserRoleName;
      const redirectPage = getRedirectPage(roleName);
      router.replace({ name: redirectPage, params: { projectId: Object.keys(appState.projects)[0] } });
    }
  } catch (error) {
    router.replace({ name: Pages.crash });
  }
});
</script>
