<template>
  <Dropdown>
    <template #toggle="{ toggleDropdown }">
      <button
        class="button button--w-icon button--sm flex"
        @click="toggleDropdown"
      >
        Filter
        <span>
          <TbFilterIcon class="w-5 h-5" />
        </span>
      </button>
    </template>

    <template #default="{ toggleDropdown }">
      <div class="p-4">
        <div
          v-for="key in Object.values(filters)"
          :key="key.value"
          class="mb-4"
        >
          <span class="font-semibold">{{ key.label }}</span>
          <div class="grid grid-cols-2">
            <div
              v-for="option in key.options"
              :key="option.value"
              class="p-1"
            >
              <input
                :id="option.value"
                v-model="option.checked"
                :value="option.value"
                type="checkbox"
                class="mr-2 checked:bg-primary-800 hover:cursor-pointer"
              >
              <label
                :for="option.value"
                class="hover:cursor-pointer"
              >{{ option.label }}</label>
            </div>
          </div>
        </div>

        <div class="flex gap-2">
          <button
            class="button button--outline button--sm"
            @click="toggleDropdown"
          >
            Cancel
          </button>
          <button
            class="button button--primary button--sm"
            @click="props.applyFilters"
          >
            Apply
          </button>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { TbFilterIcon } from '@/components/tasty_bistro';
import Dropdown from './dropdown.vue';

export interface FilterOption {
  value: string;
  label: string;
  checked: boolean;
}

interface FilterType {
  value: string;
  label: string;
  options: FilterOption[] | undefined;
}

interface Props {
  showFilters: boolean;
  filters: Record<string, FilterType>
  cancelFilters: () => void;
  applyFilters: () => void;
  toggleFilter: () => void;
}
const props = withDefaults(defineProps<Props>(), {
  showFilters: false,
});
</script>
