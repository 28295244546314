<template>
  <div class="max-h-full max-w-full">
    <div class="my-6">
      <HighlightCard
        title="Conversion Rate"
        period="year"
        :value="percentage(props.dataset[props.dataset.length -1] / (props.dataset[0] || 1))"
        :percentage-diff="null"
      >
        <template #benchmark>
          {{ checkoutBenchmark(
            { category: 'conversionRate', value: props.dataset[props.dataset.length -1] / (props.dataset[0] || 1), view }
          )
          }}
        </template>

        <template #icon>
          <TbGraphUpIcon class="w-5 h-5" />
        </template>

        <template #tooltip>
          <div class="tooltip-container">
            <div>
              <h3>
                Conversion Rate
              </h3>
            </div>
            <p>
              The percentage of customers that apply for Elective that complete their purchase.
            </p>

            <p>
              The benchmarks for this metric are:
            </p>

            <div class="flex flex-col mt-2">
              <span
                v-for="key of Object.keys(percentileToIcon)"
                :key="key"
              >
                {{ percentileToIcon[key] }}: {{ key }} percentile
              </span>
            </div>
          </div>
        </template>
      </HighlightCard>
    </div>

    <div class="funnel-wrapper flex justify-between w-full gap-6">
      <!-- first item -->
      <div class="grow">
        <div class="mb-4">
          <HighlightCard
            title="Applications"
            variant="secondary"
            period="year"
            :value="props.dataset[0]"
            :percentage-diff="null"
          >
            <template #icon>
              <TbEyeIcon class="w-5 h-5" />
            </template>

            <template #tooltip>
              <div class="tooltip-container">
                <h3>
                  Applications
                </h3>
                <p class="text-sm mt-2">
                  The number of applications successfully submitted by your customers to Elective
                </p>
                <p
                  v-if="isProjectOlderThanApplicationsStartDate"
                  class="text-sm mt-2"
                >
                  Note: Applications data is available as of November 16, 2022
                </p>
              </div>
            </template>
          </HighlightCard>
        </div>

        <div class="flex-grow flex items-end rounded-lg shadow-inner overflow-hidden bg-gray-100 box-content h-52 border border-gray-200">
          <div
            class="w-full shadow-md"
            :style="`background-color: ${defaultColors[0]}; height: ${ !!Number(props.dataset[0]) ? '100%' : '0%'}`"
          />
        </div>
      </div>

      <div class="stretch shrink-0 flex justify-center items-center">
        <div class="arrow">
          <div class="flex flex-col">
            <div class="flex">
              <h3 class="text-sm font-bold self-center">
                Approval Rate
              </h3>

              <TbTooltip>
                <div class="tooltip-container">
                  <h3>
                    Approval Rate
                  </h3>
                  <div class="text-sm mt-2">
                    <p>The percentage of customers who apply to pay with Elective who then get approved</p>

                    <p>
                      The benchmarks for this metric are:
                    </p>

                    <div class="flex flex-col mt-2">
                      <span
                        v-for="key of Object.keys(percentileToIcon)"
                        :key="key"
                      >
                        {{ percentileToIcon[key] }}: {{ key }} percentile
                      </span>
                    </div>
                  </div>
                </div>
              </TbTooltip>
            </div>

            <div class="flex flex-row gap-3 items-center">
              <div class="text-lg">
                {{ percentage(props.dataset[1] / (props.dataset[0] || 1), 0) }}
              </div>
              <div class="px-3 text-lg bg-white rounded-md">
                {{ checkoutBenchmark({ category: 'approvalRate', value: props.dataset[1] / (props.dataset[0] || 1), view }) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- second item -->
      <div class="grow">
        <div class="mb-4">
          <HighlightCard
            title="Approved"
            variant="secondary"
            period="year"
            :value="props.dataset[1]"
            :percentage-diff="percentageDiff(props.dataset[1], props.datasetYoy?.[1] || 0)"
          >
            <template #icon>
              <TbCheckBadgeIcon class="w-5 h-5" />
            </template>

            <template #tooltip>
              <div class="tooltip-container">
                <h3>
                  Approved
                </h3>
                <p class="text-sm mt-2">
                  The number of customers who were approved to pay through Elective
                </p>
              </div>
            </template>
          </HighlightCard>
        </div>

        <div class="flex-grow flex items-end rounded-lg shadow-inner overflow-hidden bg-gray-100 box-content h-52 border border-gray-200">
          <div
            class="w-full shadow-md"
            :style="`background-color: ${defaultColors[1]}; height: ${ (props.dataset[1] / props.dataset[0]) * 100}%`"
          />
        </div>
      </div>

      <div class="stretch shrink-0 flex justify-center items-center">
        <div class="arrow">
          <div class="flex flex-col">
            <div class="flex">
              <h3 class="text-sm font-bold self-center">
                Take Up Rate
              </h3>

              <TbTooltip>
                <div class="tooltip-container">
                  <h3>
                    Take-Up Rate
                  </h3>
                  <div class="text-sm mt-2">
                    <p>The percentage of customers who are approved for Elective who then follow through with their purchase</p>

                    <p>
                      The benchmarks for this metric are:
                    </p>

                    <div class="flex flex-col mt-2">
                      <span
                        v-for="key of Object.keys(percentileToIcon)"
                        :key="key"
                      >
                        {{ percentileToIcon[key] }}: {{ key }} percentile
                      </span>
                    </div>
                  </div>
                </div>
              </TbTooltip>
            </div>

            <div class="flex flex-row gap-3 items-center">
              <div class="text-lg">
                {{ percentage(props.dataset[2] / (props.dataset[1] || 1), 0) }}
              </div>
              <div class="px-3 text-lg bg-white rounded-md">
                {{ checkoutBenchmark({ category: 'takeUpRate', value: props.dataset[2] / (props.dataset[1] || 1), view }) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- third -->
      <div class="grow">
        <div class="mb-4">
          <HighlightCard
            title="Purchased"
            variant="secondary"
            period="year"
            :value="props.dataset[2]"
            :percentage-diff="null"
          >
            <template #icon>
              <TbUserCircleIcon class="w-5 h-5" />
            </template>

            <template #tooltip>
              <div class="tooltip-container">
                <h3>
                  Purchased
                </h3>
                <p class="text-sm mt-2">
                  The number of customers that completed their purchase through Elective
                </p>
              </div>
            </template>
          </HighlightCard>
        </div>
        <div class="flex-grow flex items-end rounded-lg shadow-inner overflow-hidden bg-gray-100 box-content h-52 border border-gray-200">
          <div
            class="w-full shadow-md"
            :style="`background-color: ${defaultColors[2]}; height: ${ (props.dataset[2] / props.dataset[0]) * 100}%`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defaultColors } from '@/lib/graphs/colors';
import { percentage, percentageDiff } from '@/filters/percentage';
import {
  TbGraphUpIcon, TbEyeIcon, TbCheckBadgeIcon, TbUserCircleIcon, TbTooltip,
} from '@/components/tasty_bistro';
import { checkoutBenchmark, percentileToIcon } from '@/filters/benchmark';
import { View } from '@/pages/app/project/dashboard/home/api/get_overview';
import HighlightCard from './highlight_card.vue';

export interface Props {
  labels: string[];
  dataset: number[];
  datasetYoy?: number[];
  view: View;
  isProjectOlderThanApplicationsStartDate: boolean;
}

const props = defineProps<Props>();

</script>

<style lang="scss">
.funnel-wrapper {
  flex-direction: row;

  @media (max-width: 1120px ) {
    flex-direction: column;
  }
}

.arrow {
  @media (max-width: 1120px ) {
    margin-right: 0;
    margin-top: 0;
    top: 0;
  }

  @apply bg-gray-200 rounded-md py-2 px-4 relative top-[44px];
}
</style>
