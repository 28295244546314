<template>
  <div class="h-full flex flex-col gap-2">
    <div
      v-if="$slots['escape-hatch']"
      class="flex mb-2"
    >
      <slot name="escape-hatch" />
    </div>
    <div class="flex flex-wrap justify-between gap-4">
      <h2 class="text-lg font-bold">
        {{ action === 'create' ? 'Create' : 'Update' }} Payment Setting
      </h2>
    </div>

    <div class="flex-grow w-full overflow-hidden">
      <form
        class="grow-1 flex flex-col justify-between h-full overflow-hidden"
        @submit.prevent="submit"
      >
        <!-- potential overflow -->
        <div class="flex-grow overflow-auto">
          <TbInput
            v-model="name"
            class="mb-6"
            :disabled="formState === FormState.submitting"
            label="Name"
            :errors="nameError && [nameError]"
          />

          <!-- Lnpl feature switch -->
          <FeatureSwitch
            v-model="lnplEnabled"
            :disabled="!hasLnplAccess"
            class="mb-4"
          >
            <template #title>
              <div class="feature-title">
                <TbChartPieIcon class="w-5 h-5" />
                Learn Now, Pay Later (LNPL)
              </div>
            </template>

            <template #description>
              <p class="text-sm mt-2">
                Give your customers the freedom to pay over 3, 6, 9, or 12 months while you get paid upfront in full.
              </p>
            </template>
          </FeatureSwitch>

          <section
            v-if="!hasPpmAccess"
            class="w-full whitespace-normal bg-gray-100 p-3 mb-3 rounded-md"
          >
            <h4 class="text-base feature-title mb-2">
              <TbIcon
                icon="lock"
              /> Unlock Elective Billing - Stop Chasing Payments! 🙌
            </h4>
            <div class="flex flex-col gap-4">
              <div class="flex flex-col gap-3 max-w-full items-start justify-between sm:flex-row sm:gap-6 sm:items-center">
                <p class="text-sm sm:max-w-[65%]">
                  Leading online education businesses turn to Elective Billing to boost conversion rates,
                  increase collections, and empower their teams.
                </p>

                <TypeformButton
                  button-text="Request Invite"
                  disabled-text="Joined waitlist!"
                  :form-id="typeformFormId"
                  :user-email="appState.user.email"
                />
              </div>

              <Collapse>
                <template #label="{ open }">
                  <div
                    class="flex flex-row items-center gap-1 text-gray-500"
                  >
                    <p class="text-sm">
                      Learn more
                    </p>
                    <TbChevronDownIcon
                      :class="[
                        'h-4 w-4 transition duration-150 ease-in-out',
                        open && 'transform rotate-180',
                      ]"
                    />
                  </div>
                </template>

                <template #content>
                  <ul>
                    <li class="info-list-item">
                      <TbIcon
                        icon="circle-check"
                      />Easily connect your payment gateway to Elective ⚙️
                    </li>
                    <li class="info-list-item">
                      <TbIcon
                        icon="circle-check"
                      />Let your customers pay upfront in full or over time 💰
                    </li>
                    <li class="info-list-item">
                      <TbIcon
                        icon="circle-check"
                      />Payment options are set entirely by you, not Elective 🙏
                    </li>
                    <li class="info-list-item">
                      <TbIcon
                        icon="circle-check"
                      />You get paid over time as payments are collected by Elective 📈
                    </li>
                    <li class="info-list-item">
                      <TbIcon
                        icon="circle-check"
                      />Failed payment recoveries and billing support managed by Elective 🚀
                    </li>
                  </ul>
                </template>
              </Collapse>
            </div>
          </section>

          <!-- pay in full switch -->
          <FeatureSwitch
            v-if="hasPpmAccess"
            v-model="payInFullEnabled"
            :disabled="!hasPayInFullAccess"
            class="mb-4"
          >
            <template #title>
              <div class="feature-title">
                <TbBankNotesIcon class="w-5 h-5" />
                Pay in Full
              </div>
            </template>

            <template
              v-if="hasPpmAccess"
              #description
            >
              <p class="text-sm mt-2">
                Allow your customers to complete their purchase with a single payment.
                You'll get paid as payment is received.
              </p>
            </template>
          </FeatureSwitch>

          <!-- ppm billing switch -->
          <FeatureSwitch
            v-if="hasPpmAccess"
            v-model="ppmEnabled"
            class="mb-4"
          >
            <template #title>
              <div class="feature-title">
                <TbCreditCardIcon class="w-5 h-5" />
                Elective Billing
              </div>
            </template>

            <template
              v-if="hasPpmAccess"
              #description
            >
              <p class="text-sm mt-2">
                Offer your own payment plans while we take care of billing and collections.
                You'll be paid as payments are made. If Learn Now Pay Later is enabled,
                these plans will only be offered to those not eligible for funding.
              </p>
            </template>
          </FeatureSwitch>

          <!-- payment plan designer -->
          <div
            v-if="hasPpmAccess && ppmEnabled"
            class="w-full py-2 overflow-hidden"
          >
            <h2 class="my-2 text-lg font-bold">
              Add Payment Plans
            </h2>

            <div class="mb-4 whitespace-normal">
              Payments will be split evenly across the payment plan terms that you set up.
              You can set up a custom down payment amount when creating or updating your checkouts.
            </div>

            <div
              v-if="paymentPlansError"
              class="error text-error-900"
            >
              <small class="error">
                {{ paymentPlansError }}
              </small>
            </div>

            <div class="flex flex-col gap-2">
              <div
                v-for="(paymentPlan) in computedPlans"
                :key="paymentPlan.installments"
                class="flex flex-row gap-1 justify-center mb-2"
              >
                <div class="flex-grow">
                  <TbSelect
                    v-model.number="paymentPlan.installments"
                    class="mb-1"
                    :options="getOpenInstallments(paymentPlan.installments)"
                    label="# of Payments"
                  />
                </div>
                <div class="flex-grow">
                  <Select
                    v-model="paymentPlan.frequency"
                    disabled
                    class="mb-1"
                    :options="frequencies"
                    :default-options="{}"
                    label="Payment Frequency"
                  />
                </div>
                <TbTrashIcon
                  class="mt-9 w-5 h-5 cursor-pointer"
                  @click="removePaymentPlan(paymentPlan.installments)"
                />
              </div>
            </div>

            <button
              class="button text-gray-500 font-semibold bg-gray-100 new-term-button ml-1 mt-3"
              type="button"
              :disabled="realLength === installments.length"
              @click="addPaymentPlan"
            >
              <span class="flex items-center gap-2">
                <TbPlusIcon class="w-4 h-4" />
                New Term
              </span>
            </button>
          </div>
        </div>

        <div
          v-if="error"
          class="text-center error text-error-900"
        >
          <small class="error">
            {{ error }}
          </small>
        </div>

        <div class="mt-4 action flex flex-col">
          <button
            class="button button--primary button--block"
            type="submit"
            :disabled="!isFormSubmittable || formState === FormState.submitting"
          >
            {{ action === 'create' ? 'Create' : 'Update' }}
          </button>

          <button
            class="button mt-2 button--text button--block"
            :disabled="formState === FormState.submitting"
            @click="cancel"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref, computed, onMounted, watch, inject, Ref,
} from 'vue';
import {
  object, string, InferType, boolean, mixed, array, number,
} from 'yup';
import { useForm, useField } from 'vee-validate';
import {
  TbInput, TbBankNotesIcon, TbChartPieIcon, TbCreditCardIcon, TbPlusIcon, TbTrashIcon, TbSelect, TbIcon, TbChevronDownIcon,
} from '@/components/tasty_bistro';
import { AxiosError } from 'axios';
import { FormState } from '@/types';
import { tracker } from '@openreplay/tracker';
import Select from '@/components/select.vue';
import TypeformButton from '@/components/typeform_button/index.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import Collapse from '@/pages/app/project/dashboard/v2/components/collapse/index.vue';
import { useRoute } from 'vue-router';
import FeatureSwitch from '../feature_switch/index.vue';
import { getConfigs } from './api/get_configs';
import { PaymentStack } from '../../payment_settings/api/get_stacks';

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const route = useRoute();
const projectId = route.params.projectId as string;
const hasLnplAccess = appState.value.projects[projectId].features.lnplEnabled;
const hasPpmAccess = appState.value.projects[projectId].features.ppmEnabled;
const hasPayInFullAccess = appState.value.projects[projectId].features.payInFullEnabled;

interface PaymentPlan {
  frequency: string;
  installments: number;
}

interface Installment {
  name: number;
  value: number;
}

interface Frequency {
  name: string;
  value: string;
}

interface Props {
    action: 'create' | 'update';
    stack?: PaymentStack;
    fn: (stack: any) => Promise<void>;
}

const typeformFormId = ref<string>('eMrVNEbp');
const installments = ref<Installment[]>([]);

const frequencies: Frequency[] = [
  {
    name: 'Monthly',
    value: 'monthly',
  },
];

const emit = defineEmits([
  'submit',
  'cancel',
]);

const props = defineProps<Props>();

const formState = ref<FormState>(FormState.ready);

const createProductSchema = object({
  name: string().required('Required'),
  lnplEnabled: boolean().required('Required'),
  ppmEnabled: boolean().required('required'),
  payInFullEnabled: boolean().required('Required'),
  paymentPlans: mixed().when('ppmEnabled', {
    is: true,
    then: array().of(object({
      frequency: string().required('Required'),
      installments: number().required('Required'),
    })).min(1, 'At least one payment plan is required when Elective Billing is enabled'),
    otherwise: array().of(object({
      frequency: string().required('Required'),
      installments: number().required('Required'),
    })).min(0),
  }),
});

interface CreateProduct extends InferType<typeof createProductSchema> {}

const { handleSubmit } = useForm<CreateProduct>({
  validationSchema: createProductSchema,
  initialValues: {
    name: props.stack?.name || '',
    lnplEnabled: props.stack?.lnplEnabled || false,
    ppmEnabled: !!props.stack?.paymentPlans.filter((pp) => pp.installments !== 1).length || false,
    paymentPlans: props.stack?.paymentPlans || [],
    payInFullEnabled: !!props.stack?.paymentPlans.find((p) => p.installments === 1) || false,
  },
});

const { value: name, errorMessage: nameError } = useField('name', {}, {
  validateOnValueUpdate: false,
});
const { value: lnplEnabled } = useField<boolean>('lnplEnabled', {}, {
  validateOnValueUpdate: false,
});
const { value: payInFullEnabled } = useField<boolean>('payInFullEnabled', {}, {
  validateOnValueUpdate: false,
});
const { value: ppmEnabled } = useField<boolean>('ppmEnabled', {}, {
  validateOnValueUpdate: false,
});
const { value: paymentPlans, errorMessage: paymentPlansError } = useField<PaymentPlan[]>('paymentPlans', {}, {
  validateOnValueUpdate: false,
});

const realLength = computed(() => paymentPlans.value.filter((p) => p.installments !== 1).length);

const getOpenInstallments = (value?: number) => {
  const usedInstallments = paymentPlans.value.filter((p) => p.installments !== value).map((pp) => pp.installments);
  return installments.value.filter((i) => !usedInstallments.includes(i.value));
};

onMounted(async () => {
  const response = await getConfigs(projectId);

  installments.value = Array.from({ length: response.maxInstallments }, (_, index) => index + 1)
    .filter((i) => i !== 1)
    .map((i) => ({
      value: i,
      name: i,
    }));
});

const addPaymentPlan = () => {
  const firstEmpty = installments.value.filter((i) => !paymentPlans.value.some((pp) => pp.installments === i.value));
  paymentPlans.value.push({
    frequency: frequencies[0].value,
    installments: firstEmpty[0].value,
  });
};

const computedPlans = computed(() => {
  const plans = paymentPlans.value.filter((p) => p.installments !== 1).sort((a, b) => a.installments - b.installments);
  return plans;
});

const tmpPpmEnabled = props.action === 'update' ? computedPlans.value.length > 0 : ppmEnabled.value;

watch(() => tmpPpmEnabled, (newVal) => {
  ppmEnabled.value = newVal;
});

const removePaymentPlan = (installment: number) => {
  if (paymentPlans.value.length === 1) {
    return;
  }

  paymentPlans.value = paymentPlans.value.filter((p) => p.installments !== installment);
};

watch(payInFullEnabled, (newVal) => {
  if (newVal) {
    paymentPlans.value = [...paymentPlans.value, {
      frequency: frequencies[0].value,
      installments: 1,
    }];
  } else {
    paymentPlans.value = paymentPlans.value.filter((p) => p.installments !== 1);
  }
});

const error = ref<string>('');
const submit = handleSubmit(async (values) => {
  error.value = '';
  formState.value = FormState.submitting;

  const form = {
    lnplEnabled: values.lnplEnabled,
    ppmEnabled: !!(values.ppmEnabled && computedPlans.value.length > 0) || payInFullEnabled.value,
    name: values.name,
    paymentPlans: values.ppmEnabled ? values.paymentPlans : values.paymentPlans.filter((p: any) => p.installments === 1),
  };

  try {
    tracker.event(`payment-settings:${props.action}:submit`, { form });
    await props.fn(form);

    tracker.event(`payment-settings:${props.action}:success`, { form });
    formState.value = FormState.ready;
  } catch (err) {
    tracker.event(`payment-settings:${props.action}:error`, { form }, true);
    formState.value = FormState.error;
    if (err instanceof AxiosError) {
      error.value = err.response?.data?.message;
    } else {
      error.value = 'An error occurred';
    }
  }
});

const allTermsValid = computed(() => paymentPlans.value.every((entry) => {
  if (entry.installments === 0) return false;

  return true;
}));

const isFormSubmittable = computed(() => {
  if (!lnplEnabled.value && !payInFullEnabled.value && !ppmEnabled.value) {
    return false;
  }

  if (ppmEnabled.value && paymentPlans.value.length === 0) {
    return false;
  }

  if (ppmEnabled.value && !allTermsValid.value) {
    return false;
  }

  if (props.action === 'create') {
    // are all required fields ready
    return !!name.value;
  }

  const primitivesChanged = props.stack?.lnplEnabled !== lnplEnabled.value
    || props.stack?.ppmEnabled !== ppmEnabled.value
    || props.stack?.name !== name.value
    || props.stack?.paymentPlans?.length !== paymentPlans.value.length;

  if (primitivesChanged) return true;

  const paymentPlansChanged = props.stack?.paymentPlans?.some((pp) => {
    const found = paymentPlans.value.find((p) => p.installments === pp.installments);
    if (!found) return true;

    return found.frequency !== pp.frequency;
  });

  return paymentPlansChanged;
});

async function cancel() {
  emit('cancel');
}
</script>

<style lang="scss" scoped>
  .feature-title {
    @apply flex flex-row gap-2 items-center font-semibold
  }

  .info-list-item {
    @apply
      flex
      flex-row
      gap-2
      items-center
      mb-2
      last-of-type:mb-0
      text-sm
      sm:whitespace-nowrap
  }
</style>
