<template>
  <img
    v-if="props.inverted"
    :src="logoInverted"
    alt="Elective Logo"
  >
  <img
    v-else
    :src="logo"
    alt="Elective Logo"
  >
</template>

<script setup lang="ts">
import logo from '@/assets/images/elective_logo_short.svg';
import logoInverted from '@/assets/images/elective_logo_short_inverted.svg';

interface Props {
  inverted: boolean
}

const props = withDefaults(defineProps<Props>(), {
  inverted: false,
});
</script>
