<template>
  <div class="flex flex-col justify-between h-full">
    <div>
      <h2 class="text-lg font-bold text-primary mb-4">
        Success! Your payment has been processed 🎉
      </h2>
      <p class="text-primary font-light leading-relaxed tracking-wide mb-6">
        We just sent an email confirmation to {{ customerEmailAddress ?? 'the customer' }}.
      </p>
      <div>
        <div class="row">
          <p class="col-keys">
            Amount Paid
          </p>
          <p class="generic-text">
            {{ amountPaid }}
          </p>
        </div>
        <div class="row">
          <p class="col-keys">
            Payment Date
          </p>
          <p class="generic-text">
            {{ paymentDate }}
          </p>
        </div>
        <div class="row">
          <p class="col-keys">
            Payment Method
          </p>
          <div class="flex items-center text-primary text-base font-bold leading-relaxed tracking-wide">
            <CardIcon :variant="brand" />&nbsp;
            • • • • {{ cardNumber }}
          </div>
        </div>
      </div>
    </div>
    <button
      class="button button--primary button--sm w-full"
      @click="emit('close')"
    >
      Done
    </button>
  </div>
</template>

<script setup lang="ts">
import CardIcon from '@/components/credit_card_icon/index.vue';
import { CreditCardBrand } from '@/types';

defineProps<{
  customerEmailAddress: string | null;
  amountPaid: string;
  paymentDate: string;
  cardNumber: string | null;
  brand: CreditCardBrand | null;
}>();

const emit = defineEmits(['close']);

</script>

<style lang="scss" scoped>
.col-keys {
  @apply text-center text-gray-400 font-light leading-relaxed tracking-wide;
}
.row {
  @apply flex justify-between mb-4;
}

.generic-text {
  @apply text-center text-primary font-bold leading-relaxed tracking-wide;
}
</style>
