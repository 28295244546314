<template>
  <div class="mt-4 bg-[#ffe9e9] border-error border-2 rounded-md py-3 px-5">
    <div class="flex items-center gap-2">
      <TbIcon
        icon="circle-exclamation"
        class="w-4 h-4 text-error"
      />
      <div class="text-error font-semibold">
        Insufficient Funds
      </div>
    </div>
    <div class="mt-2 text-error">
      Your Elective balance does not have enough funds to process this {{ props.type }}.
      We will charge your account <span class="font-bold"> ending in {{ props.merchantPaymentMethod }}</span>
      for <span class="font-bold">{{ props.amountInCents && currencyInCents(props.amountInCents) }}</span> to complete this transaction.
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TbIcon } from '@/components/tasty_bistro';
import { currencyInCents } from '@/filters/currency';

const props = defineProps<{ merchantPaymentMethod: string, amountInCents: number, type: 'refund' | 'cancellation' }>();

</script>
