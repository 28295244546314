<template>
  <div
    class="px-6 py-4 m-auto mb-4 bg-white card"
  >
    <div class="flex flex-row flex-auto lg:items-center">
      <div class="items-center justify-center shrink-0 w-10 h-10 font-bold rounded-full flex bg-primary-100">
        {{ props.sequenceNumber }}
      </div>

      <div class="ml-2 md:ml-3 lg:ml-8 lg:flex justify-between lg:w-full">
        <div class="mb-2 md:mb-4 lg:mb-0 lg:mr-4 lg:leading-7">
          <h2 class="text-base font-bold">
            {{ props.name }}
          </h2>

          <p class="text-sm">
            {{ props.description }}
          </p>
        </div>

        <div class="flex items-center flex-none lg:mt-0">
          <router-link
            :to="{ name: props.actionLink }"
            class="button button--primary"
          >
            {{ props.actionText }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

  interface Props {
  name: string;
  description: string;
  sequenceNumber: string;
  actionText: string;
  actionLink: string;
}
const props = defineProps<Props>();
</script>
