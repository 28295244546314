<template>
  <div
    :class="[
      'flex flex-row gap-2 text-sm px-2 py-3 rounded-lg border border-solid',
      props.variant === 'warning' && 'warning',
      props.variant === 'error' && 'error',
      props.variant === 'info' && 'info',
      props.class
    ]"
  >
    <div>
      <svg
        v-if="props.variant === 'warning'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-5 h-5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948
          3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949
          3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12
          15.75h.007v.008H12v-.008z"
        />
      </svg>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        class="w-5 h-5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708
            2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18
            0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
    </div>
    <div class="grow">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
  interface Props {
    variant?: 'warning' | 'error' | 'info'
    class?: string | string[];
  }
const props = defineProps<Props>();
</script>

<style lang="scss" scoped>
    .warning {
        background-color: rgb(255, 246, 222);
        border-color: rgb(221, 193, 52);

        svg {
            stroke: rgb(221, 193, 52);
        }
    }

    .error {
        background-color: rgb(255, 236, 236);
        border-color: rgb(255, 107, 107);

        svg {
            stroke: rgb(255, 107, 107);
        }
    }

    .info {
        background-color: rgb(227, 241, 218);
        border-color: rgb(139, 199, 102);

        svg {
            stroke: rgb(139, 199, 102);
        }
    }
</style>
