<template>
  <div class="video-container">
    <iframe
      :src="props.videoUrl"
      frameborder="0"
      :allow="props.allowAutoPlay ? 'autoplay; fullscreen' : 'fullscreen'"
    />
  </div>
</template>

<script setup lang="ts">

interface Props {
  videoUrl: string;
  allowAutoPlay?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  allowAutoPlay: true,
});
</script>

<style lang="scss" scoped>
  .video-container {
    height: 0;
    width: 100;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
</style>
