<template>
  <TbChip
    :text="props.title"
    :small="true"
    :color="props.title === 'In-House' ? 'primary-100': 'accent-100'"
    textColor="props.title === 'In-House' ? 'primary': 'primary'"
    class=""
  />
</template>

<script setup lang="ts">
import { TbChip } from '@/components/tasty_bistro';
import { OrderOriginTitle } from '@/types';

const props = defineProps<{ title: OrderOriginTitle }>();
</script>
