<template>
  <div class="h-full w-full flex flex-col gap-2">
    <div
      v-if="$slots['escape-hatch']"
      class="flex mb-2"
    >
      <slot name="escape-hatch" />
    </div>
    <div class="flex flex-wrap justify-between gap-4">
      <h2 class="text-lg font-bold">
        {{ props.action === 'create' ? 'Create Product' : 'Update Product' }}
      </h2>

      <StatusSelect
        v-model="state"
        default="active"
        :disabled="formState === FormState.submitting"
        class="w-[220px]"
        :options="stateOptions"
        :errors="stateError && [stateError]"
      />
    </div>

    <div class="flex-grow w-full">
      <form
        class="grow-1 flex flex-col justify-between h-full"
        @submit.prevent="submit"
      >
        <div class="flex-grow">
          <TbInput
            v-model="name"
            placeholder="Product name"
            class="mb-2"
            :disabled="formState === FormState.submitting"
            label="Name"
            :errors="nameError && [nameError]"
          />

          <div
            class="flex gap-4 mb-2 flex-row"
          >
            <div class="flex-1">
              <TbInput
                ref="inputRef"
                v-model="priceInCents"
                :disabled="formState === FormState.submitting"
                placeholder="0.00"
                label="Price"
                type="text"
                :errors="priceInCentsError && [priceInCentsError]"
              />
            </div>

            <div class="flex-1">
              <TbSelect
                v-model="currencyCode"
                :disabled="action === 'update' || formState === FormState.submitting"
                label="Currency"
                :default-options="{ name: 'USD', value: 'USD' }"
                :options="[{ name: 'CAD', value: 'CAD' }]"
                :errors="currencyCodeError && [currencyCodeError]"
              />
            </div>
          </div>
          <FeatureSwitch
            v-if="hasTaxSaleEnabled"
            v-model="taxCodeForm"
            class="my-4 fswitch"
          >
            <template #title>
              <div class="feature-title">
                <img
                  src="./assets/dolar_sign.svg"
                  alt="dolar sign"
                  class="w-4 h-4 mr-2 inline-block"
                >
                <span class="font-semibold text-primary">Is this a taxable product?</span>
              </div>
            </template>

            <template
              #description
            >
              <div class="text-sm mt-2">
                <p>
                  Toggle this on to collect and report tax on this product.
                </p>
                <p>
                  See more
                  <a
                    href="https://help.elective.com/hc/en-us/articles/27528560777115"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-red-400"
                  >
                    here
                  </a>
                  for details.
                </p>
              </div>
              <div class="mt-4">
                <TbInput
                  v-if="taxCodeForm"
                  v-model="taxCode"
                  :disabled="formState === FormState.submitting"
                  label="Tax Code"
                />
              </div>
            </template>
          </FeatureSwitch>
        </div>

        <div
          v-if="error"
          class="text-center error text-error-900"
        >
          <small class="error">
            {{ error }}
          </small>
        </div>

        <div class="mt-4 action">
          <button
            class="button button--primary button--block"
            type="submit"
            :disabled="!isFormSubmittable || formState === FormState.submitting"
          >
            {{ props.action === 'create' ? 'Create' : 'Update' }}
          </button>

          <button
            class="button mt-2 button--text button--block"
            :disabled="formState === FormState.submitting"
            @click="cancel"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref, onMounted, watch, nextTick, computed, inject, Ref,
} from 'vue';
import {
  object, string, InferType, number,
} from 'yup';
import { useRoute } from 'vue-router';
import { tracker } from '@openreplay/tracker';
import { useForm, useField } from 'vee-validate';
import { TbInput, TbSelect } from '@/components/tasty_bistro';
import { AxiosError } from 'axios';
import { FormState } from '@/types';
import StatusSelect from '@/components/status_select.vue';
import { AppState } from '@/pages/app/api/get_app_state';
import { useCurrencyInput, CurrencyDisplay } from 'vue-currency-input';
import { Product } from '../../products/api/get_products';
import FeatureSwitch from '../feature_switch/index.vue';

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const route = useRoute();
const projectId = route.params.projectId as string;
const projectConfig = appState.value.projects[projectId];
const hasTaxSaleEnabled = projectConfig.configs.checkoutSalesConfigured;

const emit = defineEmits([
  'submit',
  'cancel',
]);

interface Props {
    action: 'create' | 'update';
    product?: Product;
    fn: (product: any) => Promise<void>;
}

const props = defineProps<Props>();

const formState = ref<FormState>(FormState.ready);
const taxCodeForm = ref<boolean>(!!props.product?.taxCode);

const createProductSchema = object({
  state: string().required('Required'),
  name: string().required('Required'),
  priceInCents: number().nullable().required('Required')
    .min(0)
    .typeError('Price must be a number.'),
  currencyCode: string().required('Required'),
  description: string(),
  taxCode: string().nullable(),

});

interface CreateProductParams extends InferType<typeof createProductSchema> {}

const stateOptions = [
  { name: 'For Sale', value: 'active', color: 'bg-success-900' },
  { name: 'Not For Sale', value: 'inactive', color: 'bg-warning-900' },
];

const { handleSubmit } = useForm<CreateProductParams>({
  validationSchema: createProductSchema,
  validateOnInput: false,
  initialValues: {
    state: props.product?.state || 'active',
    description: props.product?.description || '',
    name: props.product?.name || '',
    taxCode: props.product?.taxCode || undefined,
    // @ts-ignore
    priceInCents: props.product?.priceInCents ? props.product.priceInCents / 100 : null,
    currencyCode: props.product?.currencyCode || 'USD',
  },
});

const { value: state, errorMessage: stateError } = useField<string>('state', {}, {
  validateOnValueUpdate: false,
});
const { value: name, errorMessage: nameError } = useField('name', {}, {
  validateOnValueUpdate: false,
});

const { value: taxCode } = useField('taxCode', {}, {
  validateOnValueUpdate: false,
});

const { value: description } = useField('description', {}, {
  validateOnValueUpdate: false,
});
const { value: priceInCents, errorMessage: priceInCentsError } = useField<number>('priceInCents', {}, {
  validateOnValueUpdate: false,
});
const { value: currencyCode, errorMessage: currencyCodeError } = useField<string>('currencyCode', {}, {
  validateOnValueUpdate: false,
});

const {
  inputRef, setOptions, setValue, numberValue,
} = useCurrencyInput({
  currency: currencyCode.value,
  currencyDisplay: CurrencyDisplay.code,
}, true);

watch(currencyCode, (newVal) => {
  setOptions({
    currency: newVal,
  });
});

onMounted(async () => {
  await nextTick();
  setValue(priceInCents.value);
});

const error = ref<string>('');
const submit = handleSubmit(async (values) => {
  error.value = '';
  formState.value = FormState.submitting;

  if (!numberValue.value) {
    error.value = 'Price must be a number.';
    return;
  }

  const form = {
    ...values,
    priceInCents: numberValue.value * 100,
  };

  try {
    if (!taxCodeForm.value && props.product?.taxCode) {
      (values as CreateProductParams).taxCode = null;
    } else if (!taxCodeForm.value && !props.product?.taxCode) {
      (values as CreateProductParams).taxCode = undefined;
    }

    tracker.event(`product:${props.action}:submit`, { form });
    await props.fn(form);
    tracker.event(`product:${props.action}:success`, { form });

    formState.value = FormState.ready;
  } catch (err) {
    tracker.event(`product:${props.action}:error`, { form }, true);
    formState.value = FormState.error;
    if (err instanceof AxiosError) {
      error.value = err.response?.data?.message;
    } else {
      error.value = 'An error occurred';
    }
  }
});

const isFormSubmittable = computed(() => {
  if (props.action === 'create') {
    // are all required fields ready
    return state.value && name.value && priceInCents.value && currencyCode.value;
  }

  const result = props.product?.state !== state.value
    || props.product?.name !== name.value
    || props.product?.description !== description.value
    || props.product?.priceInCents !== priceInCents.value * 100
    || props.product?.currencyCode !== currencyCode.value
    || props.product?.taxCode !== taxCode.value
    || taxCodeForm.value !== !!props.product?.taxCode;

  return result;
});

async function cancel() {
  emit('cancel');
}
</script>

<style scoped>
*, *::before, *::after {
    box-sizing: border-box;
}
.fswitch :deep #toggle {
    @apply mt-6 self-baseline;
}
</style>
