<template>
  <div>
    <div class="flex items-center">
      <slot name="icon" />

      <h2
        class="ml-2 text-lg font-bold"
        :class="[props.variant === 'secondary' ? 'text-gray-500' : '']"
      >
        {{ title }}
      </h2>

      <TbTooltip>
        <div class="bg-white">
          <slot name="tooltip" />
        </div>
      </TbTooltip>
    </div>

    <div>
      <h3 class="text-2xl font-regular">
        {{ value }}
        <slot
          v-if="$slots.benchmark"
          name="benchmark"
        />
      </h3>

      <!-- <div class="flex flex-row items-center gap-1 text-gray-300">
        <div
          v-if="percentageDiff"
          class="flex flex-row items-center"
          :class="percentageDiff > 0
            ? 'text-success'
            : percentageDiff < 0
              ? 'text-error'
              : 'text-gray-400'"
        >
          <div
            v-show="isValidPercentage"
            className="h-4 w-4 flex justify-center items-center"
          >
            <TbArrowUpIcon
              v-if="percentageDiff > 0"
              class="h-3 stroke-2 stroke-current"
            />

            <TbArrowDownIcon
              v-else-if="percentageDiff < 0"
              class="h-3 stroke-2 stroke-current"
            />
          </div>

          <span class="text-current font-semibold mr-2">
            {{ percentage(Math.abs(percentageDiff)) }}
          </span>

          <span class="text-gray-400">
            from previous {{ period }}.
          </span>
        </div>

        <div v-else>
          <span class="text-current font-semibold">-%</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TbTooltip } from '@/components/tasty_bistro';

interface Props {
  percentageDiff: number | null;
  title: string;
  value: string | number;
  period: string;
  variant?: string;
}

const props = defineProps<Props>();

// uncomment and used when ops greenlights feature
// const isValidPercentage = computed(() => !Number.isNaN(props.percentageDiff) && Number.isFinite(props.percentageDiff));
</script>
