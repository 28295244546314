<template>
  <Popper
    mode="hover"
    :disabled="props.disabled"
    :traverse="props.traverse"
  >
    <template #trigger="{ assignReference, show, hide }">
      <slot
        :assignReference="assignReference"
        name="trigger"
        :show="show"
        :hide="hide"
      />
    </template>

    <template #tooltip>
      <div class="max-w-[300px] shadow-md rounded-md text-sm bg-white p-3 font-light">
        <slot name="tooltip" />
      </div>
    </template>
  </Popper>
</template>

<script lang="ts" setup>
import Popper from './popper.vue';

  interface Props {
      disabled?: boolean;
      traverse?: boolean;
  }

const props = defineProps<Props>();
</script>
