<template>
  <div
    class="welcome-wrapper h-full w-full flex md:items-center justify-center md:px-8"
  >
    <div
      class="w-full p-6 md:w-2/3 lg:w-1/2 max-w-2xl rounded-md gap-5 bg-white flex flex-col items-center justify-start"
    >
      <h1 class="mt-6 text-2xl font-bold">
        Welcome to Elective 🚀
      </h1>
      <div class="w-[90%]">
        <div>
          <div
            class="cursor-pointer"
            @click="showModal = true"
          >
            <img
              src="./assets/thumbnail.png"
              class="w-full"
            >
          </div>
          <Modal
            :showModal="showModal"
            @close-modal="handleCloseModal"
          >
            <div class="w-[70%]">
              <Video :videoUrl="videoUrl" />
            </div>
          </Modal>
        </div>
      </div>
      <div class="w-[90%] md:text-center">
        <p>
          "We're thrilled to have you on board. You're on the verge of unlocking a
          world of possibilities, supercharging your sales, and removing price as a
          barrier for new customers. We're here to support you through every step of
          your journey."
        </p>
        <p class="pt-2">
          - John Najafi, Founder & CEO
        </p>
      </div>
      <div>
        <button
          class="button button--sm button--w-icon flex border-solid border-2 border-primary"
          @click="videoEventCompleted"
        >
          {{ watchedVideo ? 'Continue' : 'Skip' }}
          <span>
            <TbArrowRightIcon class="w-5 h-5" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, inject, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TbArrowRightIcon } from '@/components/tasty_bistro';
import { AppState } from '@/pages/app/api/get_app_state';
import Video from '@/components/video.vue';
import Modal from '@/components/modal.vue';
import { Pages } from '@/router';
import { updateVideoStatus } from './api/update_video_status';

const router = useRouter();
const route = useRoute();
const projectId = route.params.projectId as string;

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;

const showModal = ref(false);
const watchedVideo = ref(false);

const handleCloseModal = () => {
  showModal.value = false;
  watchedVideo.value = true;
};

const videoEventCompleted = async () => {
  await updateVideoStatus(projectId);

  const { showDemoPage } = appState.value.projects[projectId].actions;

  if (showDemoPage) {
    router.replace({ name: Pages.scheduleDemo, params: { projectId } });
  } else {
    router.replace({ name: Pages.onboarding, params: { projectId } });
  }
};

const videoUrl = 'https://player.vimeo.com/video/864560076?autoplay=1';
</script>

<style scoped lang="scss">
.welcome-wrapper {
  background-image: url("/background_gradients/mobile.png");
}

/* Tablet styles */
@media (min-width: 768px) {
  .welcome-wrapper {
    background-image: url("/background_gradients/tablet.png");
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .welcome-wrapper {
    background-image: url("/background_gradients/desktop.png");
  }
}
</style>
