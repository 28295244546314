<template>
  <div class="flex w-full overflow-hidden bg-white rounded-xl">
    <main class="flex w-full">
      <div class="left-card">
        <slot />
      </div>

      <div class="right-card">
        <ImageWithQuote
          :quote="props.quote"
          :name="props.name"
          :designation="props.designation"
          :imageName="props.imageName"
        />
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import ImageWithQuote from '@/components/image_with_quote/index.vue';

interface Props {
  quote: string;
  name: string;
  designation: string;
  imageName: string;
}
const props = defineProps<Props>();
</script>

<style lang="scss">
  .left-card {
    @apply
      w-full
      lg:w-1/2;
  }

  .right-card {
    background-size: cover;
    background-position: center;
    @apply
      lg:w-1/2
      relative;
  }
</style>
