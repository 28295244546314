<template>
  <button
    type="submit"
    :class="`w-full my-4 button button--${color}`"
    :disabled="props.disabled"
  >
    <TbIcon
      v-if="props.isLoading"
      icon="spinner"
      class="text-xl text-white animate-spin"
    />
    <div v-else>
      {{ props.text }}
    </div>
  </button>
</template>

<script setup lang="ts">
import { TbIcon } from '@/components/tasty_bistro';

interface Props {
  disabled: boolean;
  isLoading?: boolean;
  text: string;
  color?: string;
}
const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  color: 'secondary',
});
</script>
